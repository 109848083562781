declare var require: any;
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-oservice',
  templateUrl: './oservice.component.html',
  styleUrls: ['./oservice.component.scss']
})
export class OserviceComponent implements OnInit {
  data: Array<object>;
  public button: object;

  constructor(private sanitizer: DomSanitizer, private titleService: Title) {
    this.titleService.setTitle("Ohward - O'services");
    this.data = require("../../../assets/data/fr/service.json").data;
    this.data.forEach((article) => {
      article["image"] = this.sanitizer.bypassSecurityTrustStyle(`url(${article["image"]})`);
    });
    this.button = null;
  }

  ngOnInit() {
    this.button = require(`src/assets/i18n/fr.json`).TOOLS.OSERVICE.BUTTON || null;
  }

}
