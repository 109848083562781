declare var require: any;

import { Component, OnInit, SystemJsNgModuleLoader, HostListener, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import WorkerGroup from "../_models/workerGroup";
import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"]
})
export class AboutUsComponent implements OnInit {
  public teamData: Array<WorkerGroup>;
  public showStaff: string;
  public windowScrolled: boolean;
  public sideList: Array<string>;

  constructor(public translate: TranslateService, @Inject(DOCUMENT) private document: Document, private titleService: Title) {
    this.titleService.setTitle("Ohward - À propos");
    this.teamData = require(`src/assets/data/${this.translate.currentLang}/team.json`).groups;
    this.teamData.forEach(group => {
      group.workers.forEach((member) => {
        member.picture = `${member.picture}`;
      });
    });
    this.showStaff = "";
    this.sideList = require(`src/assets/i18n/${this.translate.currentLang}.json`)["ABOUT-US"].FIRST_SECTION.SIDE_LIST;
  }

  ngOnInit() { }

  hideModal() {
    this.showStaff = "";
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const limit = document.querySelector("section:first-child").scrollHeight * 0.7;
    if ((window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop) > limit) {
          this.windowScrolled = true;
      } else if (this.windowScrolled &&
        (window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop) < limit) {
          this.windowScrolled = false;
      }
  }

  scrollToTop() {
      (function smoothscroll() {
          const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.scrollTo(0, 0);
          }
      })();
  }}
