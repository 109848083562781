declare var require: any;
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"]
})
export class BlogComponent implements OnInit {
  data: Array<Object>;

  constructor(private sanitizer: DomSanitizer, private titleService: Title) {
    this.titleService.setTitle("Ohward - Blog");
    this.data = require("../../../assets/data/fr/blog.json").data;
    this.data.forEach((article) => {
      article["image"] = this.sanitizer.bypassSecurityTrustStyle(`url(${article["image"]})`);
    });
  }

  ngOnInit() {
  }

}
