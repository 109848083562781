import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AboutUsComponent } from "./about-us/about-us.component";
import { HomeComponent } from "./home/home.component";
import { PrestationsComponent } from "./prestations/prestations.component";
import { SolutionsComponent } from "./tools/solutions/solutions.component";
import { OstoriesComponent } from "./tools/ostories/ostories.component";
import { OfeedbackComponent } from "./tools/ofeedback/ofeedback.component";
import { OserviceComponent } from "./tools/oservice/oservice.component";
import { ConditionsComponent } from "./conditions/conditions.component";
import { ToursUniversityComponent } from "./ressources/tours-university/tours-university.component";
import { AnalysisModelComponent } from "./ressources/analysis-model/analysis-model.component";
import { UsecaseComponent } from "./ressources/usecase/usecase.component";
import { BlogComponent } from "./ressources/blog/blog.component";
import { PressComponent } from "./ressources/press/press.component";
import { ContactComponent } from "./contact/contact.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "prestations", component: PrestationsComponent },
  { path: "ressource", component: ConditionsComponent },
  { path: "contact", component: ContactComponent },
  
  { path: "ressources/tours-university", component: ToursUniversityComponent },
  { path: "ressources/analysis-model", component: AnalysisModelComponent },
  { path: "ressources/press", component: PressComponent },
  { path: "ressources/blog", component: BlogComponent },
  {
    path: "ressources/usecase",
    component: UsecaseComponent
  },
  
  // { path: "ressources/support", component: SupportComponent },
  { path: "tools/solutions", component: SolutionsComponent },
  { path: "tools/ostories", component: OstoriesComponent },
  { path: "tools/ofeedback", component: OfeedbackComponent },
  { path: "tools/oservices", component: OserviceComponent },

  { path: "**", redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
