import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-tours-university",
  templateUrl: "./tours-university.component.html",
  styleUrls: ["./tours-university.component.scss"]
})
export class ToursUniversityComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Ohward - Université de Tours");
  }

  ngOnInit() {
  }

}
