declare var require: any;
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-ostories",
  templateUrl: "./ostories.component.html",
  styleUrls: ["./ostories.component.scss"]
})
export class OstoriesComponent implements OnInit {
  public button: object;

  constructor(private titleService: Title) {
    this.titleService.setTitle("Ohward - O'stories");
    this.button = null;
  }

  ngOnInit() {
    this.button = require(`src/assets/i18n/fr.json`).TOOLS.OSTORIES.BUTTON || null;
  }

}
