declare var require: any;

import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnInit, HostBinding } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, Title } from "@angular/platform-browser";
import HomeCards from "../_models/homeCards";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  public cards: Array<HomeCards>;
  public iams: Array<HomeCards>;
  public images: Array<string> = require("../../assets/pictures/companies/export.json").companies;
  public firstRowChosenBlock: number = null;
  public windowScrolled: boolean;
  private numbers: Array<number>;
  private finalNumbers: Array<number>;

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--carousel-width: ${this.images.length * -100}%`);
  }

  constructor(private sanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document, private titleService: Title) {
    this.titleService.setTitle("Ohward - Accueil");
  }

  ngOnInit() {
    this.cards = require("../../assets/i18n/fr.json").HOME.SECOND_SECTION.BLOCKS;
    this.iams = require("../../assets/i18n/fr.json").HOME.FOURTH_SECTION.BLOCKS;
    this.numbers = [ 0, 0 ];
    this.finalNumbers = require("../../assets/i18n/fr.json").HOME.THIRD_SECTION.PARAGRAPHS.map((elem) => parseInt(elem.DIGIT, null));
  }

  toggleClass(event: Event) {
    let eventTarget = event.target as HTMLElement;
    while (eventTarget.tagName.toLowerCase() === "p" || !eventTarget.classList.contains("parent-div")) {
      eventTarget = eventTarget.parentElement;
    }
    if (!eventTarget.classList.contains("blured")) {
      eventTarget.classList.remove("unblured");
      eventTarget.classList.add("blured");
    } else if (eventTarget.classList.contains("blured")) {
      eventTarget.classList.add("unblured");
      eventTarget.classList.remove("blured");
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // for button to go to top
    const limit = document.querySelector("section:first-child").scrollHeight * 0.7;
    // for digits incrementing on scroll
    const firstNumbLimit = document.querySelector("#first-number").getBoundingClientRect().top;
    const secondNumbLimit = document.querySelector("#second-number").getBoundingClientRect().top;
    if ((window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop) > limit) {
          this.windowScrolled = true;
      } else if (this.windowScrolled &&
        (window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop) < limit) {
          this.windowScrolled = false;
      }
    if (firstNumbLimit <= window.innerHeight * 0.75) {
      if (this.numbers[0] < this.finalNumbers[0]) {
        this.incrementNumbers(0);
      }
    }
    if (secondNumbLimit <= window.innerHeight * 0.75) {
      if (this.numbers[1] < this.finalNumbers[1]) {
        this.incrementNumbers(1);
      }
    }
  }

  scrollToTop() {
    (function smoothscroll() {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.scrollTo(0, 0);
        }
    })();
  }

  incrementNumbers(index: number) {
    if (this.numbers[index] < this.finalNumbers[index]) {
      this.numbers[index] += 1;
    }
    if (this.numbers[index] < this.finalNumbers[index]) {
      setTimeout(() => this.incrementNumbers(index), 3000 / this.finalNumbers[index]);
    }
  }
}
