import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-analysis-model",
  templateUrl: "./analysis-model.component.html",
  styleUrls: ["./analysis-model.component.scss"]
})
export class AnalysisModelComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Ohward - Modèle d'analyse");
  }

  ngOnInit() {
  }

}
