declare var require: any;
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-ofeedback",
  templateUrl: "./ofeedback.component.html",
  styleUrls: ["./ofeedback.component.scss"]
})
export class OfeedbackComponent implements OnInit {
  public button: object;

  constructor(private titleService: Title) {
    this.titleService.setTitle("Ohward - O'feedback");
    this.button = null;
  }

  ngOnInit() {
    this.button = require(`src/assets/i18n/fr.json`).TOOLS.OFEEDBACK.BUTTON || null;
  }

}
