declare var require: any;
import { Component, OnInit, HostListener, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-prestations",
  templateUrl: "./prestations.component.html",
  styleUrls: ["./prestations.component.scss"]
})
export class PrestationsComponent implements OnInit {
  public ids: Array<string>;
  public windowScrolled: boolean;
  public lists: Array<Array<string>>;
  public links: Array<object>;

  constructor(@Inject(DOCUMENT) private document: Document, private titleService: Title) {
    this.titleService.setTitle("Ohward - Prestations");
    this.ids = [];
    this.lists = [];
    this.links = [];
  }

  ngOnInit() {
    const presta = require("../../assets/i18n/fr.json").PRESTATIONS;
    Object.keys(presta).forEach((key) => {
      if (key.substr(0, 5) !== "FIRST") {
        this.ids.push(presta[key].TITLE.toLowerCase().replace(/ /g, "-"));
        this.lists.push([...presta[key].LIST.CONTENT]);
        this.links.push(presta[key].BUTTON || null);
      }
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const limit = document.querySelector("section:first-child").scrollHeight * 0.7;
    if ((window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop) > limit) {
          this.windowScrolled = true;
      } else if (this.windowScrolled &&
        (window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop) < limit) {
          this.windowScrolled = false;
      }
  }

  scrollToTop() {
      (function smoothscroll() {
          const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.scrollTo(0, 0);
          }
      })();
  }
}
