import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-conditions",
  templateUrl: "./conditions.component.html",
  styleUrls: ["./conditions.component.scss"]
})
export class ConditionsComponent implements OnInit {
  public show: string;

  constructor(private route: ActivatedRoute) {
    this.show = (this.route.snapshot.queryParams.content || "mentions").toLowerCase();
    if (!["mentions", "conditions"].includes(this.show)) { this.show = "mentions"; }
  }

  ngOnInit() {
  }

}
