import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  author = "";
  company = "";
  mobilephone = "";
  object = "";
  authorMail = "";
  message = "";

  constructor(private titleService: Title, private http: HttpClient) {
    this.titleService.setTitle("Ohward - Contact");
  }

  ngOnInit() {}

  checkEmail() {
    // tslint:disable-next-line: max-line-length
    const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (!this.authorMail) {
      return false;
    }

    if (this.authorMail.length > 256) {
      return false;
    }

    if (!tester.test(this.authorMail)) {
      return false;
    }

    // Further checking of some things regex can't handle
    const [account, address] = this.authorMail.split("@");
    if (account.length > 64) {
      return false;
    }

    const domainParts = address.split(".");
    if (
      domainParts.some((part) => {
        return part.length > 63;
      })
    ) {
      return false;
    }

    return true;
  }

  checkForm() {
    return (
      this.checkEmail() &&
      this.author.trim().length > 0 &&
      this.company.trim().length > 0 &&
      this.object.trim().length > 0 &&
      this.mobilephone.trim().length > 0 &&
      this.authorMail.trim().length > 0 &&
      this.message.trim().length > 0 &&
      this.message.trim().length < 280
    );
  }

  sendContent() {
    if (this.checkForm() === false) {
      if (this.authorMail.trim().length > 0 && !this.checkEmail()) {
        alert("L'adresse mail n'est pas valide ❌");
      } else if (this.message.length >= 280) {
        alert("Le message est trop long ❌");
      } else {
        alert("Veuillez compléter entièrement le formulaire ❌");
      }
    } else {
      this.http
        .post(
          "https://fr.ohward.com/email",
          {
            Name: this.author,
            Company: this.company,
            Mobilephone: this.mobilephone,
            Object: this.object,
            Mail: this.authorMail,
            Message: this.message,
          },
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
            responseType: "text",
          }
        )
        .subscribe(
          (res) => {
            alert("Formulaire envoyé ✔️");
            document.location.reload();
          },
          (err) => {
            console.error("Erreur : ", err);
          }
        );
    }
  }
}
