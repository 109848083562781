import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgsRevealModule } from "ngx-scrollreveal";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { NavbarComponent } from "./navbar/navbar.component";

import { AppRoutingModule } from "./app-routing.module";
import { AboutUsComponent } from "./about-us/about-us.component";
import { PrestationsComponent } from "./prestations/prestations.component";
import { SolutionsComponent } from "./tools/solutions/solutions.component";
import { OstoriesComponent } from "./tools/ostories/ostories.component";
import { OfeedbackComponent } from "./tools/ofeedback/ofeedback.component";
import { UsecaseComponent } from "./ressources/usecase/usecase.component";
import { ConditionsComponent } from "./conditions/conditions.component";
import { ToursUniversityComponent } from "./ressources/tours-university/tours-university.component";
import { ContactComponent } from "./contact/contact.component";
import { AnalysisModelComponent } from "./ressources/analysis-model/analysis-model.component";
import { BlogComponent } from "./ressources/blog/blog.component";
import { PressComponent } from "./ressources/press/press.component";
import { FooterComponent } from "./footer/footer.component";
import { OserviceComponent } from './tools/oservice/oservice.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "../assets/i18n/");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    AboutUsComponent,
    PrestationsComponent,
    UsecaseComponent,
    SolutionsComponent,
    OstoriesComponent,
    OfeedbackComponent,
    ConditionsComponent,
    ToursUniversityComponent,
    ContactComponent,
    AnalysisModelComponent,
    BlogComponent,
    PressComponent,
    FooterComponent,
    OserviceComponent
  ],
  imports: [
    FormsModule,
    NgbModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    NgsRevealModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang("fr");
    translate.use("fr");
  }
}
