import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-solutions",
  templateUrl: "./solutions.component.html",
  styleUrls: ["./solutions.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SolutionsComponent implements OnInit {
  constructor(private titleService: Title) {
    this.titleService.setTitle("Ohward - Solutions");
  }

  ngOnInit() {}
}
